<template>
  <div class="coupon-content-setting">
    <PageTitle
      title="使用券設定"
      btn="新增"
      @btnClick="$router.push('/coupon-create')"
    />

    <el-table :data="couponList">
      <EmptyBlock slot="empty" />
      <el-table-column prop="Image" label="圖片" align="center">
        <template slot-scope="scope">
          <img
            style="margin: auto"
            :width="`${imgSize}px`"
            :src="imgSource(scope.row.Image)"
            alt=""
          >
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名稱" align="center" />
      <el-table-column prop="expDay" label="領取後可使用天數" align="center">
        <template slot-scope="scope">
          <p>{{ scope.row.expDay || '-' }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="expDay" label="特定截止日期" align="center">
        <template slot-scope="scope">
          <p>
            {{ scope.row.expAt ? formatDateTime(scope.row.expAt) : '-' }}
          </p>
        </template>
      </el-table-column>

      <el-table-column
        prop="name"
        label="操作"
        fixed="right"
        width="110"
        align="center"
      >
        <template slot-scope="scope">
          <TableEditBtnGroup
            @edit="editCoupon(scope.row)"
            @delete=";(deleteDialog = true), (selectRow = scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="couponCount"
      @pageChange="refresh"
    />

    <DeleteDialog
      v-if="deleteDialog"
      @close="deleteDialog = false"
      @delete="deleteCoupon(), (deleteDialog = false)"
    />
  </div>
</template>

<script>
import tableMixin from '@/mixin/table'
import dialogMixin from '@/mixin/dialog'
import { imgSrc } from '@/utils/helper'
import { GetCoupon, GetCouponCount, DeleteCoupon } from '@/api/lottery/coupon'
import { formatDate } from '@/utils/date'
// import * as cssvars from '@/styles/ohbot/_variables.scss'

export default {
  name: 'CouponContentSetting',
  mixins: [tableMixin, dialogMixin],
  data: () => ({
    couponList: [],
    couponCount: 0,
    imgSize: 90,
  }),

  async mounted () {
    await this.refresh()
  },
  async activated () {
    await this.refresh()
  },
  methods: {
    imgSource (image) {
      return imgSrc(this.imgSize, image)
    },

    async getCoupon () {
      const [res, err] = await GetCoupon({
        shopId: this.shop,
        start: this.pageStartIndex,
        limit: this.tableOptions.pageLimit,
      })
      if (err) return this.$message.error(err)
      this.couponList = res
    },

    editCoupon (row) {
      console.log(row)
      this.$router.push(`/coupon-create/${row.id}`)
    },

    //= > 取得總數
    async deleteCoupon () {
      console.log(this.selectRow)
      const [, err] = await DeleteCoupon({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      if (err) return this.$message.error(err)
      await this.refresh()
    },

    //= > 取得總數
    async getCouponCount () {
      const [res, err] = await GetCouponCount({
        shopId: this.shop,
      })
      if (err) return this.$message.error(err)
      this.couponCount = res.count
    },

    async refresh () {
      await this.getCoupon()
      await this.getCouponCount()
    },

    formatDateTime (time) {
      return formatDate(time, 'YYYY/MM/DD HH:mm:ss')
    },
  },
}
</script>

<style lang="postcss" scoped>
.page-header {
  @apply flex justify-between items-center;
}
</style>
